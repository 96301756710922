export const regions = ["All", "Africa", "Asia", "Australia", "Central America", "Europe", "North America", "South America"]

export const locationData = [
  {
    "countryName": "Afghanistan",
    "capital": "Kabul",
    "lat": "34.51666667",
    "lng": "69.183333",
    "countryCode": "AF",
    "continent": "Asia"
  },
  {
    "countryName": "Aland Islands",
    "capital": "Mariehamn",
    "lat": "60.116667",
    "lng": "19.9",
    "countryCode": "AX",
    "continent": "Europe"
  },
  {
    "countryName": "Albania",
    "capital": "Tirana",
    "lat": "41.31666667",
    "lng": "19.816667",
    "countryCode": "AL",
    "continent": "Europe"
  },
  {
    "countryName": "Algeria",
    "capital": "Algiers",
    "lat": "36.75",
    "lng": "3.05",
    "countryCode": "DZ",
    "continent": "Africa"
  },
  {
    "countryName": "American Samoa",
    "capital": "Pago Pago",
    "lat": "-14.26666667",
    "lng": "-170.7",
    "countryCode": "AS",
    "continent": "Australia"
  },
  {
    "countryName": "Andorra",
    "capital": "Andorra la Vella",
    "lat": "42.5",
    "lng": "1.516667",
    "countryCode": "AD",
    "continent": "Europe"
  },
  {
    "countryName": "Angola",
    "capital": "Luanda",
    "lat": "-8.833333333",
    "lng": "13.216667",
    "countryCode": "AO",
    "continent": "Africa"
  },
  {
    "countryName": "Anguilla",
    "capital": "The Valley",
    "lat": "18.21666667",
    "lng": "-63.05",
    "countryCode": "AI",
    "continent": "North America"
  },
  {
    "countryName": "Antarctica",
    "capital": "N/A",
    "lat": "0",
    "lng": "0",
    "countryCode": "AQ",
    "continent": "Antarctica"
  },
  {
    "countryName": "Antigua and Barbuda",
    "capital": "Saint John’s",
    "lat": "17.11666667",
    "lng": "-61.85",
    "countryCode": "AG",
    "continent": "North America"
  },
  {
    "countryName": "Argentina",
    "capital": "Buenos Aires",
    "lat": "-34.58333333",
    "lng": "-58.666667",
    "countryCode": "AR",
    "continent": "South America"
  },
  {
    "countryName": "Armenia",
    "capital": "Yerevan",
    "lat": "40.16666667",
    "lng": "44.5",
    "countryCode": "AM",
    "continent": "Europe"
  },
  {
    "countryName": "Aruba",
    "capital": "Oranjestad",
    "lat": "12.51666667",
    "lng": "-70.033333",
    "countryCode": "AW",
    "continent": "North America"
  },
  {
    "countryName": "Australia",
    "capital": "Canberra",
    "lat": "-35.26666667",
    "lng": "149.133333",
    "countryCode": "AU",
    "continent": "Australia"
  },
  {
    "countryName": "Austria",
    "capital": "Vienna",
    "lat": "48.2",
    "lng": "16.366667",
    "countryCode": "AT",
    "continent": "Europe"
  },
  {
    "countryName": "Azerbaijan",
    "capital": "Baku",
    "lat": "40.38333333",
    "lng": "49.866667",
    "countryCode": "AZ",
    "continent": "Europe"
  },
  {
    "countryName": "Bahamas",
    "capital": "Nassau",
    "lat": "25.08333333",
    "lng": "-77.35",
    "countryCode": "BS",
    "continent": "North America"
  },
  {
    "countryName": "Bahrain",
    "capital": "Manama",
    "lat": "26.23333333",
    "lng": "50.566667",
    "countryCode": "BH",
    "continent": "Asia"
  },
  {
    "countryName": "Bangladesh",
    "capital": "Dhaka",
    "lat": "23.71666667",
    "lng": "90.4",
    "countryCode": "BD",
    "continent": "Asia"
  },
  {
    "countryName": "Barbados",
    "capital": "Bridgetown",
    "lat": "13.1",
    "lng": "-59.616667",
    "countryCode": "BB",
    "continent": "North America"
  },
  {
    "countryName": "Belarus",
    "capital": "Minsk",
    "lat": "53.9",
    "lng": "27.566667",
    "countryCode": "BY",
    "continent": "Europe"
  },
  {
    "countryName": "Belgium",
    "capital": "Brussels",
    "lat": "50.83333333",
    "lng": "4.333333",
    "countryCode": "BE",
    "continent": "Europe"
  },
  {
    "countryName": "Belize",
    "capital": "Belmopan",
    "lat": "17.25",
    "lng": "-88.766667",
    "countryCode": "BZ",
    "continent": "Central America"
  },
  {
    "countryName": "Benin",
    "capital": "Porto-Novo",
    "lat": "6.483333333",
    "lng": "2.616667",
    "countryCode": "BJ",
    "continent": "Africa"
  },
  {
    "countryName": "Bermuda",
    "capital": "Hamilton",
    "lat": "32.28333333",
    "lng": "-64.783333",
    "countryCode": "BM",
    "continent": "North America"
  },
  {
    "countryName": "Bhutan",
    "capital": "Thimphu",
    "lat": "27.46666667",
    "lng": "89.633333",
    "countryCode": "BT",
    "continent": "Asia"
  },
  {
    "countryName": "Bolivia",
    "capital": "La Paz",
    "lat": "-16.5",
    "lng": "-68.15",
    "countryCode": "BO",
    "continent": "South America"
  },
  {
    "countryName": "Bosnia and Herzegovina",
    "capital": "Sarajevo",
    "lat": "43.86666667",
    "lng": "18.416667",
    "countryCode": "BA",
    "continent": "Europe"
  },
  {
    "countryName": "Botswana",
    "capital": "Gaborone",
    "lat": "-24.63333333",
    "lng": "25.9",
    "countryCode": "BW",
    "continent": "Africa"
  },
  {
    "countryName": "Brazil",
    "capital": "Brasilia",
    "lat": "-15.78333333",
    "lng": "-47.916667",
    "countryCode": "BR",
    "continent": "South America"
  },
  {
    "countryName": "British Indian Ocean Territory",
    "capital": "Diego Garcia",
    "lat": "-7.3",
    "lng": "72.4",
    "countryCode": "IO",
    "continent": "Africa"
  },
  {
    "countryName": "British Virgin Islands",
    "capital": "Road Town",
    "lat": "18.41666667",
    "lng": "-64.616667",
    "countryCode": "VG",
    "continent": "North America"
  },
  {
    "countryName": "Brunei Darussalam",
    "capital": "Bandar Seri Begawan",
    "lat": "4.883333333",
    "lng": "114.933333",
    "countryCode": "BN",
    "continent": "Asia"
  },
  {
    "countryName": "Bulgaria",
    "capital": "Sofia",
    "lat": "42.68333333",
    "lng": "23.316667",
    "countryCode": "BG",
    "continent": "Europe"
  },
  {
    "countryName": "Burkina Faso",
    "capital": "Ouagadougou",
    "lat": "12.36666667",
    "lng": "-1.516667",
    "countryCode": "BF",
    "continent": "Africa"
  },
  {
    "countryName": "Burundi",
    "capital": "Bujumbura",
    "lat": "-3.366666667",
    "lng": "29.35",
    "countryCode": "BI",
    "continent": "Africa"
  },
  {
    "countryName": "Cambodia",
    "capital": "Phnom Penh",
    "lat": "11.55",
    "lng": "104.916667",
    "countryCode": "KH",
    "continent": "Asia"
  },
  {
    "countryName": "Cameroon",
    "capital": "Yaounde",
    "lat": "3.866666667",
    "lng": "11.516667",
    "countryCode": "CM",
    "continent": "Africa"
  },
  {
    "countryName": "Canada",
    "capital": "Ottawa",
    "lat": "45.41666667",
    "lng": "-75.7",
    "countryCode": "CA",
    "continent": "Central America"
  },
  {
    "countryName": "Cape Verde",
    "capital": "Praia",
    "lat": "14.91666667",
    "lng": "-23.516667",
    "countryCode": "CV",
    "continent": "Africa"
  },
  {
    "countryName": "Cayman Islands",
    "capital": "George Town",
    "lat": "19.3",
    "lng": "-81.383333",
    "countryCode": "KY",
    "continent": "North America"
  },
  {
    "countryName": "Central African Republic",
    "capital": "Bangui",
    "lat": "4.366666667",
    "lng": "18.583333",
    "countryCode": "CF",
    "continent": "Africa"
  },
  {
    "countryName": "Chad",
    "capital": "N’Djamena",
    "lat": "12.1",
    "lng": "15.033333",
    "countryCode": "TD",
    "continent": "Africa"
  },
  {
    "countryName": "Chile",
    "capital": "Santiago",
    "lat": "-33.45",
    "lng": "-70.666667",
    "countryCode": "CL",
    "continent": "South America"
  },
  {
    "countryName": "China",
    "capital": "Beijing",
    "lat": "39.91666667",
    "lng": "116.383333",
    "countryCode": "CN",
    "continent": "Asia"
  },
  {
    "countryName": "Christmas Island",
    "capital": "The Settlement",
    "lat": "-10.41666667",
    "lng": "105.716667",
    "countryCode": "CX",
    "continent": "Australia"
  },
  {
    "countryName": "Cocos Islands",
    "capital": "West Island",
    "lat": "-12.16666667",
    "lng": "96.833333",
    "countryCode": "CC",
    "continent": "Australia"
  },
  {
    "countryName": "Colombia",
    "capital": "Bogota",
    "lat": "4.6",
    "lng": "-74.083333",
    "countryCode": "CO",
    "continent": "South America"
  },
  {
    "countryName": "Comoros",
    "capital": "Moroni",
    "lat": "-11.7",
    "lng": "43.233333",
    "countryCode": "KM",
    "continent": "Africa"
  },
  {
    "countryName": "Cook Islands",
    "capital": "Avarua",
    "lat": "-21.2",
    "lng": "-159.766667",
    "countryCode": "CK",
    "continent": "Australia"
  },
  {
    "countryName": "Costa Rica",
    "capital": "San Jose",
    "lat": "9.933333333",
    "lng": "-84.083333",
    "countryCode": "CR",
    "continent": "Central America"
  },
  {
    "countryName": "Cote d’Ivoire",
    "capital": "Yamoussoukro",
    "lat": "6.816666667",
    "lng": "-5.266667",
    "countryCode": "CI",
    "continent": "Africa"
  },
  {
    "countryName": "Croatia",
    "capital": "Zagreb",
    "lat": "45.8",
    "lng": "16",
    "countryCode": "HR",
    "continent": "Europe"
  },
  {
    "countryName": "Cuba",
    "capital": "Havana",
    "lat": "23.11666667",
    "lng": "-82.35",
    "countryCode": "CU",
    "continent": "North America"
  },
  {
    "countryName": "CuraÃ§ao",
    "capital": "Willemstad",
    "lat": "12.1",
    "lng": "-68.916667",
    "countryCode": "CW",
    "continent": "North America"
  },
  {
    "countryName": "Cyprus",
    "capital": "Nicosia",
    "lat": "35.16666667",
    "lng": "33.366667",
    "countryCode": "CY",
    "continent": "Europe"
  },
  {
    "countryName": "Czech Republic",
    "capital": "Prague",
    "lat": "50.08333333",
    "lng": "14.466667",
    "countryCode": "CZ",
    "continent": "Europe"
  },
  {
    "countryName": "Democratic Republic of the Congo",
    "capital": "Kinshasa",
    "lat": "-4.316666667",
    "lng": "15.3",
    "countryCode": "CD",
    "continent": "Africa"
  },
  {
    "countryName": "Denmark",
    "capital": "Copenhagen",
    "lat": "55.66666667",
    "lng": "12.583333",
    "countryCode": "DK",
    "continent": "Europe"
  },
  {
    "countryName": "Djibouti",
    "capital": "Djibouti",
    "lat": "11.58333333",
    "lng": "43.15",
    "countryCode": "DJ",
    "continent": "Africa"
  },
  {
    "countryName": "Dominica",
    "capital": "Roseau",
    "lat": "15.3",
    "lng": "-61.4",
    "countryCode": "DM",
    "continent": "North America"
  },
  {
    "countryName": "Dominican Republic",
    "capital": "Santo Domingo",
    "lat": "18.46666667",
    "lng": "-69.9",
    "countryCode": "DO",
    "continent": "North America"
  },
  {
    "countryName": "Ecuador",
    "capital": "Quito",
    "lat": "-0.216666667",
    "lng": "-78.5",
    "countryCode": "EC",
    "continent": "South America"
  },
  {
    "countryName": "Egypt",
    "capital": "Cairo",
    "lat": "30.05",
    "lng": "31.25",
    "countryCode": "EG",
    "continent": "Africa"
  },
  {
    "countryName": "El Salvador",
    "capital": "San Salvador",
    "lat": "13.7",
    "lng": "-89.2",
    "countryCode": "SV",
    "continent": "Central America"
  },
  {
    "countryName": "Equatorial Guinea",
    "capital": "Malabo",
    "lat": "3.75",
    "lng": "8.783333",
    "countryCode": "GQ",
    "continent": "Africa"
  },
  {
    "countryName": "Eritrea",
    "capital": "Asmara",
    "lat": "15.33333333",
    "lng": "38.933333",
    "countryCode": "ER",
    "continent": "Africa"
  },
  {
    "countryName": "Estonia",
    "capital": "Tallinn",
    "lat": "59.43333333",
    "lng": "24.716667",
    "countryCode": "EE",
    "continent": "Europe"
  },
  {
    "countryName": "Ethiopia",
    "capital": "Addis Ababa",
    "lat": "9.033333333",
    "lng": "38.7",
    "countryCode": "ET",
    "continent": "Africa"
  },
  {
    "countryName": "Falkland Islands",
    "capital": "Stanley",
    "lat": "-51.7",
    "lng": "-57.85",
    "countryCode": "FK",
    "continent": "South America"
  },
  {
    "countryName": "Faroe Islands",
    "capital": "Torshavn",
    "lat": "62",
    "lng": "-6.766667",
    "countryCode": "FO",
    "continent": "Europe"
  },
  {
    "countryName": "Federated States of Micronesia",
    "capital": "Palikir",
    "lat": "6.916666667",
    "lng": "158.15",
    "countryCode": "FM",
    "continent": "Australia"
  },
  {
    "countryName": "Fiji",
    "capital": "Suva",
    "lat": "-18.13333333",
    "lng": "178.416667",
    "countryCode": "FJ",
    "continent": "Australia"
  },
  {
    "countryName": "Finland",
    "capital": "Helsinki",
    "lat": "60.16666667",
    "lng": "24.933333",
    "countryCode": "FI",
    "continent": "Europe"
  },
  {
    "countryName": "France",
    "capital": "Paris",
    "lat": "48.86666667",
    "lng": "2.333333",
    "countryCode": "FR",
    "continent": "Europe"
  },
  {
    "countryName": "French Polynesia",
    "capital": "Papeete",
    "lat": "-17.53333333",
    "lng": "-149.566667",
    "countryCode": "PF",
    "continent": "Australia"
  },
  {
    "countryName": "French Southern and Antarctic Lands",
    "capital": "Port-aux-FranÃ§ais",
    "lat": "-49.35",
    "lng": "70.216667",
    "countryCode": "TF",
    "continent": "Antarctica"
  },
  {
    "countryName": "Gabon",
    "capital": "Libreville",
    "lat": "0.383333333",
    "lng": "9.45",
    "countryCode": "GA",
    "continent": "Africa"
  },
  {
    "countryName": "Georgia",
    "capital": "Tbilisi",
    "lat": "41.68333333",
    "lng": "44.833333",
    "countryCode": "GE",
    "continent": "Europe"
  },
  {
    "countryName": "Germany",
    "capital": "Berlin",
    "lat": "52.51666667",
    "lng": "13.4",
    "countryCode": "DE",
    "continent": "Europe"
  },
  {
    "countryName": "Ghana",
    "capital": "Accra",
    "lat": "5.55",
    "lng": "-0.216667",
    "countryCode": "GH",
    "continent": "Africa"
  },
  {
    "countryName": "Gibraltar",
    "capital": "Gibraltar",
    "lat": "36.13333333",
    "lng": "-5.35",
    "countryCode": "GI",
    "continent": "Europe"
  },
  {
    "countryName": "Greece",
    "capital": "Athens",
    "lat": "37.98333333",
    "lng": "23.733333",
    "countryCode": "GR",
    "continent": "Europe"
  },
  {
    "countryName": "Greenland",
    "capital": "Nuuk",
    "lat": "64.18333333",
    "lng": "-51.75",
    "countryCode": "GL",
    "continent": "Central America"
  },
  {
    "countryName": "Grenada",
    "capital": "Saint George’s",
    "lat": "12.05",
    "lng": "-61.75",
    "countryCode": "GD",
    "continent": "North America"
  },
  {
    "countryName": "Guam",
    "capital": "Hagatna",
    "lat": "13.46666667",
    "lng": "144.733333",
    "countryCode": "GU",
    "continent": "Australia"
  },
  {
    "countryName": "Guatemala",
    "capital": "Guatemala City",
    "lat": "14.61666667",
    "lng": "-90.516667",
    "countryCode": "GT",
    "continent": "Central America"
  },
  {
    "countryName": "Guernsey",
    "capital": "Saint Peter Port",
    "lat": "49.45",
    "lng": "-2.533333",
    "countryCode": "GG",
    "continent": "Europe"
  },
  {
    "countryName": "Guinea",
    "capital": "Conakry",
    "lat": "9.5",
    "lng": "-13.7",
    "countryCode": "GN",
    "continent": "Africa"
  },
  {
    "countryName": "Guinea-Bissau",
    "capital": "Bissau",
    "lat": "11.85",
    "lng": "-15.583333",
    "countryCode": "GW",
    "continent": "Africa"
  },
  {
    "countryName": "Guyana",
    "capital": "Georgetown",
    "lat": "6.8",
    "lng": "-58.15",
    "countryCode": "GY",
    "continent": "South America"
  },
  {
    "countryName": "Haiti",
    "capital": "Port-au-Prince",
    "lat": "18.53333333",
    "lng": "-72.333333",
    "countryCode": "HT",
    "continent": "North America"
  },
  {
    "countryName": "Heard Island and McDonald Islands",
    "capital": "N/A",
    "lat": "0",
    "lng": "0",
    "countryCode": "HM",
    "continent": "Antarctica"
  },
  {
    "countryName": "Honduras",
    "capital": "Tegucigalpa",
    "lat": "14.1",
    "lng": "-87.216667",
    "countryCode": "HN",
    "continent": "Central America"
  },
  {
    "countryName": "Hong Kong",
    "capital": "N/A",
    "lat": "0",
    "lng": "0",
    "countryCode": "HK",
    "continent": "Asia"
  },
  {
    "countryName": "Hungary",
    "capital": "Budapest",
    "lat": "47.5",
    "lng": "19.083333",
    "countryCode": "HU",
    "continent": "Europe"
  },
  {
    "countryName": "Iceland",
    "capital": "Reykjavik",
    "lat": "64.15",
    "lng": "-21.95",
    "countryCode": "IS",
    "continent": "Europe"
  },
  {
    "countryName": "India",
    "capital": "New Delhi",
    "lat": "28.6",
    "lng": "77.2",
    "countryCode": "IN",
    "continent": "Asia"
  },
  {
    "countryName": "Indonesia",
    "capital": "Jakarta",
    "lat": "-6.166666667",
    "lng": "106.816667",
    "countryCode": "ID",
    "continent": "Asia"
  },
  {
    "countryName": "Iran",
    "capital": "Tehran",
    "lat": "35.7",
    "lng": "51.416667",
    "countryCode": "IR",
    "continent": "Asia"
  },
  {
    "countryName": "Iraq",
    "capital": "Baghdad",
    "lat": "33.33333333",
    "lng": "44.4",
    "countryCode": "IQ",
    "continent": "Asia"
  },
  {
    "countryName": "Ireland",
    "capital": "Dublin",
    "lat": "53.31666667",
    "lng": "-6.233333",
    "countryCode": "IE",
    "continent": "Europe"
  },
  {
    "countryName": "Isle of Man",
    "capital": "Douglas",
    "lat": "54.15",
    "lng": "-4.483333",
    "countryCode": "IM",
    "continent": "Europe"
  },
  {
    "countryName": "Israel",
    "capital": "Jerusalem",
    "lat": "31.76666667",
    "lng": "35.233333",
    "countryCode": "IL",
    "continent": "Asia"
  },
  {
    "countryName": "Italy",
    "capital": "Rome",
    "lat": "41.9",
    "lng": "12.483333",
    "countryCode": "IT",
    "continent": "Europe"
  },
  {
    "countryName": "Jamaica",
    "capital": "Kingston",
    "lat": "18",
    "lng": "-76.8",
    "countryCode": "JM",
    "continent": "North America"
  },
  {
    "countryName": "Japan",
    "capital": "Tokyo",
    "lat": "35.68333333",
    "lng": "139.75",
    "countryCode": "JP",
    "continent": "Asia"
  },
  {
    "countryName": "Jersey",
    "capital": "Saint Helier",
    "lat": "49.18333333",
    "lng": "-2.1",
    "countryCode": "JE",
    "continent": "Europe"
  },
  {
    "countryName": "Jordan",
    "capital": "Amman",
    "lat": "31.95",
    "lng": "35.933333",
    "countryCode": "JO",
    "continent": "Asia"
  },
  {
    "countryName": "Kazakhstan",
    "capital": "Astana",
    "lat": "51.16666667",
    "lng": "71.416667",
    "countryCode": "KZ",
    "continent": "Asia"
  },
  {
    "countryName": "Kenya",
    "capital": "Nairobi",
    "lat": "-1.283333333",
    "lng": "36.816667",
    "countryCode": "KE",
    "continent": "Africa"
  },
  {
    "countryName": "Kiribati",
    "capital": "Tarawa",
    "lat": "-0.883333333",
    "lng": "169.533333",
    "countryCode": "KI",
    "continent": "Australia"
  },
  {
    "countryName": "Kosovo",
    "capital": "Pristina",
    "lat": "42.66666667",
    "lng": "21.166667",
    "countryCode": "KO",
    "continent": "Europe"
  },
  {
    "countryName": "Kuwait",
    "capital": "Kuwait City",
    "lat": "29.36666667",
    "lng": "47.966667",
    "countryCode": "KW",
    "continent": "Asia"
  },
  {
    "countryName": "Kyrgyzstan",
    "capital": "Bishkek",
    "lat": "42.86666667",
    "lng": "74.6",
    "countryCode": "KG",
    "continent": "Asia"
  },
  {
    "countryName": "Laos",
    "capital": "Vientiane",
    "lat": "17.96666667",
    "lng": "102.6",
    "countryCode": "LA",
    "continent": "Asia"
  },
  {
    "countryName": "Latvia",
    "capital": "Riga",
    "lat": "56.95",
    "lng": "24.1",
    "countryCode": "LV",
    "continent": "Europe"
  },
  {
    "countryName": "Lebanon",
    "capital": "Beirut",
    "lat": "33.86666667",
    "lng": "35.5",
    "countryCode": "LB",
    "continent": "Asia"
  },
  {
    "countryName": "Lesotho",
    "capital": "Maseru",
    "lat": "-29.31666667",
    "lng": "27.483333",
    "countryCode": "LS",
    "continent": "Africa"
  },
  {
    "countryName": "Liberia",
    "capital": "Monrovia",
    "lat": "6.3",
    "lng": "-10.8",
    "countryCode": "LR",
    "continent": "Africa"
  },
  {
    "countryName": "Libya",
    "capital": "Tripoli",
    "lat": "32.88333333",
    "lng": "13.166667",
    "countryCode": "LY",
    "continent": "Africa"
  },
  {
    "countryName": "Liechtenstein",
    "capital": "Vaduz",
    "lat": "47.13333333",
    "lng": "9.516667",
    "countryCode": "LI",
    "continent": "Europe"
  },
  {
    "countryName": "Lithuania",
    "capital": "Vilnius",
    "lat": "54.68333333",
    "lng": "25.316667",
    "countryCode": "LT",
    "continent": "Europe"
  },
  {
    "countryName": "Luxembourg",
    "capital": "Luxembourg",
    "lat": "49.6",
    "lng": "6.116667",
    "countryCode": "LU",
    "continent": "Europe"
  },
  {
    "countryName": "Macau",
    "capital": "N/A",
    "lat": "0",
    "lng": "0",
    "countryCode": "MO",
    "continent": "Asia"
  },
  {
    "countryName": "Macedonia",
    "capital": "Skopje",
    "lat": "42",
    "lng": "21.433333",
    "countryCode": "MK",
    "continent": "Europe"
  },
  {
    "countryName": "Madagascar",
    "capital": "Antananarivo",
    "lat": "-18.91666667",
    "lng": "47.516667",
    "countryCode": "MG",
    "continent": "Africa"
  },
  {
    "countryName": "Malawi",
    "capital": "Lilongwe",
    "lat": "-13.96666667",
    "lng": "33.783333",
    "countryCode": "MW",
    "continent": "Africa"
  },
  {
    "countryName": "Malaysia",
    "capital": "Kuala Lumpur",
    "lat": "3.166666667",
    "lng": "101.7",
    "countryCode": "MY",
    "continent": "Asia"
  },
  {
    "countryName": "Maldives",
    "capital": "Male",
    "lat": "4.166666667",
    "lng": "73.5",
    "countryCode": "MV",
    "continent": "Asia"
  },
  {
    "countryName": "Mali",
    "capital": "Bamako",
    "lat": "12.65",
    "lng": "-8",
    "countryCode": "ML",
    "continent": "Africa"
  },
  {
    "countryName": "Malta",
    "capital": "Valletta",
    "lat": "35.88333333",
    "lng": "14.5",
    "countryCode": "MT",
    "continent": "Europe"
  },
  {
    "countryName": "Marshall Islands",
    "capital": "Majuro",
    "lat": "7.1",
    "lng": "171.383333",
    "countryCode": "MH",
    "continent": "Australia"
  },
  {
    "countryName": "Mauritania",
    "capital": "Nouakchott",
    "lat": "18.06666667",
    "lng": "-15.966667",
    "countryCode": "MR",
    "continent": "Africa"
  },
  {
    "countryName": "Mauritius",
    "capital": "Port Louis",
    "lat": "-20.15",
    "lng": "57.483333",
    "countryCode": "MU",
    "continent": "Africa"
  },
  {
    "countryName": "Mexico",
    "capital": "Mexico City",
    "lat": "19.43333333",
    "lng": "-99.133333",
    "countryCode": "MX",
    "continent": "Central America"
  },
  {
    "countryName": "Moldova",
    "capital": "Chisinau",
    "lat": "47",
    "lng": "28.85",
    "countryCode": "MD",
    "continent": "Europe"
  },
  {
    "countryName": "Monaco",
    "capital": "Monaco",
    "lat": "43.73333333",
    "lng": "7.416667",
    "countryCode": "MC",
    "continent": "Europe"
  },
  {
    "countryName": "Mongolia",
    "capital": "Ulaanbaatar",
    "lat": "47.91666667",
    "lng": "106.916667",
    "countryCode": "MN",
    "continent": "Asia"
  },
  {
    "countryName": "Montenegro",
    "capital": "Podgorica",
    "lat": "42.43333333",
    "lng": "19.266667",
    "countryCode": "ME",
    "continent": "Europe"
  },
  {
    "countryName": "Montserrat",
    "capital": "Plymouth",
    "lat": "16.7",
    "lng": "-62.216667",
    "countryCode": "MS",
    "continent": "North America"
  },
  {
    "countryName": "Morocco",
    "capital": "Rabat",
    "lat": "34.01666667",
    "lng": "-6.816667",
    "countryCode": "MA",
    "continent": "Africa"
  },
  {
    "countryName": "Mozambique",
    "capital": "Maputo",
    "lat": "-25.95",
    "lng": "32.583333",
    "countryCode": "MZ",
    "continent": "Africa"
  },
  {
    "countryName": "Myanmar",
    "capital": "Rangoon",
    "lat": "16.8",
    "lng": "96.15",
    "countryCode": "MM",
    "continent": "Asia"
  },
  {
    "countryName": "Namibia",
    "capital": "Windhoek",
    "lat": "-22.56666667",
    "lng": "17.083333",
    "countryCode": "NA",
    "continent": "Africa"
  },
  {
    "countryName": "Nauru",
    "capital": "Yaren",
    "lat": "-0.5477",
    "lng": "166.920867",
    "countryCode": "NR",
    "continent": "Australia"
  },
  {
    "countryName": "Nepal",
    "capital": "Kathmandu",
    "lat": "27.71666667",
    "lng": "85.316667",
    "countryCode": "NP",
    "continent": "Asia"
  },
  {
    "countryName": "Netherlands",
    "capital": "Amsterdam",
    "lat": "52.35",
    "lng": "4.916667",
    "countryCode": "NL",
    "continent": "Europe"
  },
  {
    "countryName": "New Caledonia",
    "capital": "Noumea",
    "lat": "-22.26666667",
    "lng": "166.45",
    "countryCode": "NC",
    "continent": "Australia"
  },
  {
    "countryName": "New Zealand",
    "capital": "Wellington",
    "lat": "-41.3",
    "lng": "174.783333",
    "countryCode": "NZ",
    "continent": "Australia"
  },
  {
    "countryName": "Nicaragua",
    "capital": "Managua",
    "lat": "12.13333333",
    "lng": "-86.25",
    "countryCode": "NI",
    "continent": "Central America"
  },
  {
    "countryName": "Niger",
    "capital": "Niamey",
    "lat": "13.51666667",
    "lng": "2.116667",
    "countryCode": "NE",
    "continent": "Africa"
  },
  {
    "countryName": "Nigeria",
    "capital": "Abuja",
    "lat": "9.083333333",
    "lng": "7.533333",
    "countryCode": "NG",
    "continent": "Africa"
  },
  {
    "countryName": "Niue",
    "capital": "Alofi",
    "lat": "-19.01666667",
    "lng": "-169.916667",
    "countryCode": "NU",
    "continent": "Australia"
  },
  {
    "countryName": "Norfolk Island",
    "capital": "Kingston",
    "lat": "-29.05",
    "lng": "167.966667",
    "countryCode": "NF",
    "continent": "Australia"
  },
  {
    "countryName": "North Korea",
    "capital": "Pyongyang",
    "lat": "39.01666667",
    "lng": "125.75",
    "countryCode": "KP",
    "continent": "Asia"
  },
  {
    "countryName": "Northern Mariana Islands",
    "capital": "Saipan",
    "lat": "15.2",
    "lng": "145.75",
    "countryCode": "MP",
    "continent": "Australia"
  },
  {
    "countryName": "Norway",
    "capital": "Oslo",
    "lat": "59.91666667",
    "lng": "10.75",
    "countryCode": "NO",
    "continent": "Europe"
  },
  {
    "countryName": "Oman",
    "capital": "Muscat",
    "lat": "23.61666667",
    "lng": "58.583333",
    "countryCode": "OM",
    "continent": "Asia"
  },
  {
    "countryName": "Pakistan",
    "capital": "Islamabad",
    "lat": "33.68333333",
    "lng": "73.05",
    "countryCode": "PK",
    "continent": "Asia"
  },
  {
    "countryName": "Palau",
    "capital": "Melekeok",
    "lat": "7.483333333",
    "lng": "134.633333",
    "countryCode": "PW",
    "continent": "Australia"
  },
  {
    "countryName": "Palestine",
    "capital": "Jerusalem",
    "lat": "31.76666667",
    "lng": "35.233333",
    "countryCode": "PS",
    "continent": "Asia"
  },
  {
    "countryName": "Panama",
    "capital": "Panama City",
    "lat": "8.966666667",
    "lng": "-79.533333",
    "countryCode": "PA",
    "continent": "Central America"
  },
  {
    "countryName": "Papua New Guinea",
    "capital": "Port Moresby",
    "lat": "-9.45",
    "lng": "147.183333",
    "countryCode": "PG",
    "continent": "Australia"
  },
  {
    "countryName": "Paraguay",
    "capital": "Asuncion",
    "lat": "-25.26666667",
    "lng": "-57.666667",
    "countryCode": "PY",
    "continent": "South America"
  },
  {
    "countryName": "Peru",
    "capital": "Lima",
    "lat": "-12.05",
    "lng": "-77.05",
    "countryCode": "PE",
    "continent": "South America"
  },
  {
    "countryName": "Philippines",
    "capital": "Manila",
    "lat": "14.6",
    "lng": "120.966667",
    "countryCode": "PH",
    "continent": "Asia"
  },
  {
    "countryName": "Pitcairn Islands",
    "capital": "Adamstown",
    "lat": "-25.06666667",
    "lng": "-130.083333",
    "countryCode": "PN",
    "continent": "Australia"
  },
  {
    "countryName": "Poland",
    "capital": "Warsaw",
    "lat": "52.25",
    "lng": "21",
    "countryCode": "PL",
    "continent": "Europe"
  },
  {
    "countryName": "Portugal",
    "capital": "Lisbon",
    "lat": "38.71666667",
    "lng": "-9.133333",
    "countryCode": "PT",
    "continent": "Europe"
  },
  {
    "countryName": "Puerto Rico",
    "capital": "San Juan",
    "lat": "18.46666667",
    "lng": "-66.116667",
    "countryCode": "PR",
    "continent": "North America"
  },
  {
    "countryName": "Qatar",
    "capital": "Doha",
    "lat": "25.28333333",
    "lng": "51.533333",
    "countryCode": "QA",
    "continent": "Asia"
  },
  {
    "countryName": "Republic of Congo",
    "capital": "Brazzaville",
    "lat": "-4.25",
    "lng": "15.283333",
    "countryCode": "CG",
    "continent": "Africa"
  },
  {
    "countryName": "Romania",
    "capital": "Bucharest",
    "lat": "44.43333333",
    "lng": "26.1",
    "countryCode": "RO",
    "continent": "Europe"
  },
  {
    "countryName": "Russia",
    "capital": "Moscow",
    "lat": "55.75",
    "lng": "37.6",
    "countryCode": "RU",
    "continent": "Europe"
  },
  {
    "countryName": "Rwanda",
    "capital": "Kigali",
    "lat": "-1.95",
    "lng": "30.05",
    "countryCode": "RW",
    "continent": "Africa"
  },
  {
    "countryName": "Saint Barthelemy",
    "capital": "Gustavia",
    "lat": "17.88333333",
    "lng": "-62.85",
    "countryCode": "BL",
    "continent": "North America"
  },
  {
    "countryName": "Saint Helena",
    "capital": "Jamestown",
    "lat": "-15.93333333",
    "lng": "-5.716667",
    "countryCode": "SH",
    "continent": "Africa"
  },
  {
    "countryName": "Saint Kitts and Nevis",
    "capital": "Basseterre",
    "lat": "17.3",
    "lng": "-62.716667",
    "countryCode": "KN",
    "continent": "North America"
  },
  {
    "countryName": "Saint Lucia",
    "capital": "Castries",
    "lat": "14",
    "lng": "-61",
    "countryCode": "LC",
    "continent": "North America"
  },
  {
    "countryName": "Saint Martin",
    "capital": "Marigot",
    "lat": "18.0731",
    "lng": "-63.0822",
    "countryCode": "MF",
    "continent": "North America"
  },
  {
    "countryName": "Saint Pierre and Miquelon",
    "capital": "Saint-Pierre",
    "lat": "46.76666667",
    "lng": "-56.183333",
    "countryCode": "PM",
    "continent": "Central America"
  },
  {
    "countryName": "Saint Vincent and the Grenadines",
    "capital": "Kingstown",
    "lat": "13.13333333",
    "lng": "-61.216667",
    "countryCode": "VC",
    "continent": "Central America"
  },
  {
    "countryName": "Samoa",
    "capital": "Apia",
    "lat": "-13.81666667",
    "lng": "-171.766667",
    "countryCode": "WS",
    "continent": "Australia"
  },
  {
    "countryName": "San Marino",
    "capital": "San Marino",
    "lat": "43.93333333",
    "lng": "12.416667",
    "countryCode": "SM",
    "continent": "Europe"
  },
  {
    "countryName": "Sao Tome and Principe",
    "capital": "Sao Tome",
    "lat": "0.333333333",
    "lng": "6.733333",
    "countryCode": "ST",
    "continent": "Africa"
  },
  {
    "countryName": "Saudi Arabia",
    "capital": "Riyadh",
    "lat": "24.65",
    "lng": "46.7",
    "countryCode": "SA",
    "continent": "Asia"
  },
  {
    "countryName": "Senegal",
    "capital": "Dakar",
    "lat": "14.73333333",
    "lng": "-17.633333",
    "countryCode": "SN",
    "continent": "Africa"
  },
  {
    "countryName": "Serbia",
    "capital": "Belgrade",
    "lat": "44.83333333",
    "lng": "20.5",
    "countryCode": "RS",
    "continent": "Europe"
  },
  {
    "countryName": "Seychelles",
    "capital": "Victoria",
    "lat": "-4.616666667",
    "lng": "55.45",
    "countryCode": "SC",
    "continent": "Africa"
  },
  {
    "countryName": "Sierra Leone",
    "capital": "Freetown",
    "lat": "8.483333333",
    "lng": "-13.233333",
    "countryCode": "SL",
    "continent": "Africa"
  },
  {
    "countryName": "Singapore",
    "capital": "Singapore",
    "lat": "1.283333333",
    "lng": "103.85",
    "countryCode": "SG",
    "continent": "Asia"
  },
  {
    "countryName": "Sint Maarten",
    "capital": "Philipsburg",
    "lat": "18.01666667",
    "lng": "-63.033333",
    "countryCode": "SX",
    "continent": "North America"
  },
  {
    "countryName": "Slovakia",
    "capital": "Bratislava",
    "lat": "48.15",
    "lng": "17.116667",
    "countryCode": "SK",
    "continent": "Europe"
  },
  {
    "countryName": "Slovenia",
    "capital": "Ljubljana",
    "lat": "46.05",
    "lng": "14.516667",
    "countryCode": "SI",
    "continent": "Europe"
  },
  {
    "countryName": "Solomon Islands",
    "capital": "Honiara",
    "lat": "-9.433333333",
    "lng": "159.95",
    "countryCode": "SB",
    "continent": "Australia"
  },
  {
    "countryName": "Somalia",
    "capital": "Mogadishu",
    "lat": "2.066666667",
    "lng": "45.333333",
    "countryCode": "SO",
    "continent": "Africa"
  },
  {
    "countryName": "South Africa",
    "capital": "Pretoria",
    "lat": "-25.7",
    "lng": "28.216667",
    "countryCode": "ZA",
    "continent": "Africa"
  },
  {
    "countryName": "South Georgia and South Sandwich Islands",
    "capital": "King Edward Point",
    "lat": "-54.283333",
    "lng": "-36.5",
    "countryCode": "GS",
    "continent": "Antarctica"
  },
  {
    "countryName": "South Korea",
    "capital": "Seoul",
    "lat": "37.55",
    "lng": "126.983333",
    "countryCode": "KR",
    "continent": "Asia"
  },
  {
    "countryName": "South Sudan",
    "capital": "Juba",
    "lat": "4.85",
    "lng": "31.616667",
    "countryCode": "SS",
    "continent": "Africa"
  },
  {
    "countryName": "Spain",
    "capital": "Madrid",
    "lat": "40.4",
    "lng": "-3.683333",
    "countryCode": "ES",
    "continent": "Europe"
  },
  {
    "countryName": "Sri Lanka",
    "capital": "Colombo",
    "lat": "6.916666667",
    "lng": "79.833333",
    "countryCode": "LK",
    "continent": "Asia"
  },
  {
    "countryName": "Sudan",
    "capital": "Khartoum",
    "lat": "15.6",
    "lng": "32.533333",
    "countryCode": "SD",
    "continent": "Africa"
  },
  {
    "countryName": "Suriname",
    "capital": "Paramaribo",
    "lat": "5.833333333",
    "lng": "-55.166667",
    "countryCode": "SR",
    "continent": "South America"
  },
  {
    "countryName": "Svalbard",
    "capital": "Longyearbyen",
    "lat": "78.21666667",
    "lng": "15.633333",
    "countryCode": "SJ",
    "continent": "Europe"
  },
  {
    "countryName": "Swaziland",
    "capital": "Mbabane",
    "lat": "-26.31666667",
    "lng": "31.133333",
    "countryCode": "SZ",
    "continent": "Africa"
  },
  {
    "countryName": "Sweden",
    "capital": "Stockholm",
    "lat": "59.33333333",
    "lng": "18.05",
    "countryCode": "SE",
    "continent": "Europe"
  },
  {
    "countryName": "Switzerland",
    "capital": "Bern",
    "lat": "46.91666667",
    "lng": "7.466667",
    "countryCode": "CH",
    "continent": "Europe"
  },
  {
    "countryName": "Syria",
    "capital": "Damascus",
    "lat": "33.5",
    "lng": "36.3",
    "countryCode": "SY",
    "continent": "Asia"
  },
  {
    "countryName": "Taiwan",
    "capital": "Taipei",
    "lat": "25.03333333",
    "lng": "121.516667",
    "countryCode": "TW",
    "continent": "Asia"
  },
  {
    "countryName": "Tajikistan",
    "capital": "Dushanbe",
    "lat": "38.55",
    "lng": "68.766667",
    "countryCode": "TJ",
    "continent": "Asia"
  },
  {
    "countryName": "Tanzania",
    "capital": "Dar es Salaam",
    "lat": "-6.8",
    "lng": "39.283333",
    "countryCode": "TZ",
    "continent": "Africa"
  },
  {
    "countryName": "Thailand",
    "capital": "Bangkok",
    "lat": "13.75",
    "lng": "100.516667",
    "countryCode": "TH",
    "continent": "Asia"
  },
  {
    "countryName": "The Gambia",
    "capital": "Banjul",
    "lat": "13.45",
    "lng": "-16.566667",
    "countryCode": "GM",
    "continent": "Africa"
  },
  {
    "countryName": "Timor-Leste",
    "capital": "Dili",
    "lat": "-8.583333333",
    "lng": "125.6",
    "countryCode": "TL",
    "continent": "Asia"
  },
  {
    "countryName": "Togo",
    "capital": "Lome",
    "lat": "6.116666667",
    "lng": "1.216667",
    "countryCode": "TG",
    "continent": "Africa"
  },
  {
    "countryName": "Tokelau",
    "capital": "Atafu",
    "lat": "-9.166667",
    "lng": "-171.833333",
    "countryCode": "TK",
    "continent": "Australia"
  },
  {
    "countryName": "Tonga",
    "capital": "Nuku’alofa",
    "lat": "-21.13333333",
    "lng": "-175.2",
    "countryCode": "TO",
    "continent": "Australia"
  },
  {
    "countryName": "Trinidad and Tobago",
    "capital": "Port of Spain",
    "lat": "10.65",
    "lng": "-61.516667",
    "countryCode": "TT",
    "continent": "North America"
  },
  {
    "countryName": "Tunisia",
    "capital": "Tunis",
    "lat": "36.8",
    "lng": "10.183333",
    "countryCode": "TN",
    "continent": "Africa"
  },
  {
    "countryName": "Turkey",
    "capital": "Ankara",
    "lat": "39.93333333",
    "lng": "32.866667",
    "countryCode": "TR",
    "continent": "Europe"
  },
  {
    "countryName": "Turkmenistan",
    "capital": "Ashgabat",
    "lat": "37.95",
    "lng": "58.383333",
    "countryCode": "TM",
    "continent": "Asia"
  },
  {
    "countryName": "Turks and Caicos Islands",
    "capital": "Grand Turk",
    "lat": "21.46666667",
    "lng": "-71.133333",
    "countryCode": "TC",
    "continent": "North America"
  },
  {
    "countryName": "Tuvalu",
    "capital": "Funafuti",
    "lat": "-8.516666667",
    "lng": "179.216667",
    "countryCode": "TV",
    "continent": "Australia"
  },
  {
    "countryName": "Uganda",
    "capital": "Kampala",
    "lat": "0.316666667",
    "lng": "32.55",
    "countryCode": "UG",
    "continent": "Africa"
  },
  {
    "countryName": "Ukraine",
    "capital": "Kyiv",
    "lat": "50.43333333",
    "lng": "30.516667",
    "countryCode": "UA",
    "continent": "Europe"
  },
  {
    "countryName": "United Arab Emirates",
    "capital": "Abu Dhabi",
    "lat": "24.46666667",
    "lng": "54.366667",
    "countryCode": "AE",
    "continent": "Asia"
  },
  {
    "countryName": "United Kingdom",
    "capital": "London",
    "lat": "51.5",
    "lng": "-0.083333",
    "countryCode": "GB",
    "continent": "Europe"
  },
  {
    "countryName": "United States",
    "capital": "Washington, D.C.",
    "lat": "38.883333",
    "lng": "-77",
    "countryCode": "US",
    "continent": "Central America"
  },
  {
    "countryName": "Uruguay",
    "capital": "Montevideo",
    "lat": "-34.85",
    "lng": "-56.166667",
    "countryCode": "UY",
    "continent": "South America"
  },
  {
    "countryName": "US Minor Outlying Islands",
    "capital": "Washington, D.C.",
    "lat": "38.883333",
    "lng": "-77",
    "countryCode": "UM",
    "continent": "Australia"
  },
  {
    "countryName": "US Virgin Islands",
    "capital": "Charlotte Amalie",
    "lat": "18.35",
    "lng": "-64.933333",
    "countryCode": "VI",
    "continent": "North America"
  },
  {
    "countryName": "Uzbekistan",
    "capital": "Tashkent",
    "lat": "41.31666667",
    "lng": "69.25",
    "countryCode": "UZ",
    "continent": "Asia"
  },
  {
    "countryName": "Vanuatu",
    "capital": "Port-Vila",
    "lat": "-17.73333333",
    "lng": "168.316667",
    "countryCode": "VU",
    "continent": "Australia"
  },
  {
    "countryName": "Vatican City",
    "capital": "Vatican City",
    "lat": "41.9",
    "lng": "12.45",
    "countryCode": "VA",
    "continent": "Europe"
  },
  {
    "countryName": "Venezuela",
    "capital": "Caracas",
    "lat": "10.48333333",
    "lng": "-66.866667",
    "countryCode": "VE",
    "continent": "South America"
  },
  {
    "countryName": "Vietnam",
    "capital": "Hanoi",
    "lat": "21.03333333",
    "lng": "105.85",
    "countryCode": "VN",
    "continent": "Asia"
  },
  {
    "countryName": "Wallis and Futuna",
    "capital": "Mata-Utu",
    "lat": "-13.95",
    "lng": "-171.933333",
    "countryCode": "WF",
    "continent": "Australia"
  },
  {
    "countryName": "Western Sahara",
    "capital": "El-AaiÃºn",
    "lat": "27.153611",
    "lng": "-13.203333",
    "countryCode": "EH",
    "continent": "Africa"
  },
  {
    "countryName": "Yemen",
    "capital": "Sanaa",
    "lat": "15.35",
    "lng": "44.2",
    "countryCode": "YE",
    "continent": "Asia"
  },
  {
    "countryName": "Zambia",
    "capital": "Lusaka",
    "lat": "-15.41666667",
    "lng": "28.283333",
    "countryCode": "ZM",
    "continent": "Africa"
  },
  {
    "countryName": "Zimbabwe",
    "capital": "Harare",
    "lat": "-17.81666667",
    "lng": "31.033333",
    "countryCode": "ZW",
    "continent": "Africa"
  }
]
